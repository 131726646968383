import { initializeApp } from "firebase/app";
import { getFirestore, collection, getDocs } from "firebase/firestore/lite";
// Follow this pattern to import other Firebase services
// import { } from 'firebase/<service>';

// TODO: Replace the following with your app's Firebase project configuration
const firebaseConfig = {
  apiKey: "AIzaSyDzbKB6GMOtoKzZlyWmqG3yLuGC3YJj1Jk",
  authDomain: "wavatec-answers.firebaseapp.com",
  projectId: "wavatec-answers",
  storageBucket: "wavatec-answers.appspot.com",
  messagingSenderId: "356030128132",
  appId: "1:356030128132:web:6e5519f98490e887cad551",
};

export const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
