import React, { useState } from "react";
import Question from "../components/Question";

const FormGenerator = ({ question, onSubmitAnswer }) => {
  const [answer, setAnswer] = useState();
  const [canSubmit, setCanSubmit] = useState(false);

  const onChange = (value) => {
    setCanSubmit(Boolean(value));
    setAnswer(value);
  };

  return (
    <div className="w-full">
      {question && (
        <div className="space-y-3">
          <Question question={question} onChange={onChange} answer={answer} />
        </div>
      )}
      {/* 2 items one on the left and one on the right */}
      <div className="flex flex-col md:flex-row justify-between">
        {/* optional skip button on the left if button is not mandatory */}
        {question.mandatory && <div className="flex-1" />}
        {!question.mandatory && (
          <div className="flex justify-start mt-5">
            <button className="px-4 py-2 bg-white text-gray-600 rounded-md">
              Skip
            </button>
          </div>
        )}
        {/* next question button on the right */}
        <div className="flex justify-end mt-5">
          <button
            onClick={() => {
              setAnswer("");
              onSubmitAnswer(answer);
            }}
            disabled={
              (question.mandatory && !canSubmit) ||
              (!question.mandatory && !answer)
            }
            className={`px-4 py-2 rounded-md text-white ${
              (question.mandatory && !canSubmit) ||
              (!question.mandatory && !answer)
                ? "bg-gray-400 cursor-not-allowed"
                : "bg-blue-600"
            }`}
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
};

export default FormGenerator;
