import { Listbox } from "@headlessui/react";
import { useEffect, useState } from "react";

let people = [
  "Wade Cooper",
  "Arlene Mccoy",
  "Devon Webb",
  "Tom Cook",
  "Tanya Fox",
  "Hellen Schmidt",
  "Caroline Schultz",
  "Mason Heaney",
  "Claudie Smitham",
  "Emil Schaefer",
];

const MultiSelect = ({
  label,
  placeholder,
  id,
  onChange,
  helperText,
  isRequired,
  multiple = true,
}) => {
  console.log(multiple);
  let [active, setActivePerson] = useState(multiple ? [people[2]] : people[2]);

  return (
    <div className="z-50 relative">
      <Listbox
        multiple={multiple}
        value={active}
        onChange={(value) => {
          console.log("value:", value);
          setActivePerson(value);
        }}
      >
        <Listbox.Label className="block text-sm font-medium leading-5 text-gray-700">
          Assigned to
        </Listbox.Label>

        <div className="relative mt-2">
          <span className="inline-block w-full rounded-md shadow-sm">
            <Listbox.Button className="focus:shadow-outline-blue relative w-full cursor-default rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 text-left transition duration-150 ease-in-out focus:border-blue-300 focus:outline-none sm:text-sm sm:leading-5">
              {/* if active, show 1 option and X more */}
              <span className="block truncate">
                {multiple && active.length > 1
                  ? `${active[0]} + ${active.length - 1} more`
                  : active.join(", ")}
              </span>

              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                <svg
                  className="h-5 w-5 text-gray-400"
                  viewBox="0 0 20 20"
                  fill="none"
                  stroke="currentColor"
                >
                  <path
                    d="M7 7l3-3 3 3m0 6l-3 3-3-3"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </span>
            </Listbox.Button>
          </span>

          <div className="absolute mt-1 w-full rounded-md bg-white shadow-lg">
            <Listbox.Options className="shadow-xs max-h-60 overflow-auto rounded-md py-1 text-base leading-6 focus:outline-none sm:text-sm sm:leading-5">
              {people.map((name) => (
                <Listbox.Option
                  key={name}
                  value={name}
                  className="ui-active:bg-indigo-600 ui-active:text-white ui-not-active:text-gray-900 relative cursor-default select-none py-2 pl-3 pr-9 focus:outline-none"
                >
                  <span className="ui-selected:font-semibold ui-not-selected:font-normal block truncate">
                    {name}
                  </span>
                  {/* <span className="ui-not-selected:hidden ui-selected:flex ui-active:text-white ui-not-active:text-indigo-600 absolute inset-y-0 right-0 items-center pr-4">
                    <svg
                      className="h-5 w-5"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </span> */}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </div>
        </div>
      </Listbox>
    </div>
  );
};

export default MultiSelect;
