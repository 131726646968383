import React from "react";
import Input from "./form_components/Input";
import TextArea from "./form_components/TextArea";
import MultiSelect from "./form_components/MultiSelect";
import List from "./form_components/List";

const Question = ({ question, answer, onChange }) => {
  switch (question.type) {
    case "text":
    case "number":
    case "date":
    case "time":
    case "datetime":
    case "email":
      return (
        <Input
          label={question.label}
          placeholder={question.description}
          id={question.fid}
          isRequired={question.mandatory}
          type={question.type}
        />
      );
    case "textarea":
      return (
        <TextArea
          label={question.label}
          placeholder={question.description}
          id={question.fid}
          isRequired={question.mandatory}
        />
      );
    case "select":
      return (
        <MultiSelect
          label={question.label}
          placeholder={question.description}
          id={question.fid}
          isRequired={question.mandatory}
          options={question.options.map((option) => option.label)}
        />
      );
    default:
      return (
        <List
          label={question.label}
          options={question.options.map((option) => ({
            name: option.label,
            description: option.comment,
            value: option.value,
          }))}
          selected={answer}
          onChange={onChange}
        />
      );
  }
};

export default Question;
