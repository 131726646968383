import React from "react";

const TextArea = ({
  label,
  placeholder,
  id,
  onChange,
  helperText,
  isRequired,
  ...props
}) => {
  return (
    <div>
      <label
        htmlFor="price"
        className="block text-sm font-medium leading-6 text-gray-900"
      >
        {label}
        {isRequired && <span className="text-red-500">*</span>}
      </label>
      <div className="relative mt-2 rounded-md shadow-sm">
        <textarea
          name={id}
          id={id}
          onChange={onChange}
          placeholder={placeholder}
          className="block w-full rounded-md border-0 py-2 px-3 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
          {...props}
        />
      </div>

      {/* Helper text */}
      {helperText && (
        <p className="mt-2 text-sm text-gray-500" id="email-description">
          {helperText}
        </p>
      )}

      {/* Error text */}
      {/* <p className="mt-2 text-sm text-red-600" id="email-error">
        Not a valid email address.
      </p> */}
    </div>
  );
};

export default TextArea;
