import React, { useEffect, useState } from "react";
import FormGenerator from "../FormGenerator/FormGenerator";
import { useParams } from "react-router-dom";

import { db } from "../utils/firebase";
import { doc, getDoc } from "firebase/firestore/lite";

const FormPage = () => {
  const [generatorData, setGeneratorData] = useState({});
  const [progress, setProgress] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [host, setHost] = useState(null);
  const [teamId, setTeamId] = useState(null);
  const [databaseId, setDatabaseId] = useState(null);
  const [header, setHeader] = useState(<></>);
  const [footer, setFooter] = useState(<></>);
  const [css, setCss] = useState("");

  const { formId } = useParams();

  const getForm = async () => {
    if (!formId || isLoading) return;
    setIsLoading(true);
    const docRef = doc(db, "forms", formId);
    const docSnap = await getDoc(docRef);

    if (!docSnap.exists()) {
      // docSnap.data() will be undefined in this case
      console.log("No such document!");

      setError("No such document!");
      setIsLoading(false);
      return;
    }

    console.log("Document data:", docSnap.data());

    const { host, teamId, databaseId } = docSnap.data();
    setHost(host);
    setTeamId(teamId);
    setDatabaseId(databaseId);
    setHeader(docSnap.data().header);
    setFooter(docSnap.data().footer);
    setCss(docSnap.data().css);

    getQuestion({ host, teamId, databaseId });
  };

  const getQuestion = async ({ host, teamId, databaseId }) => {
    setIsLoading(true);
    // get Question
    const response = await fetch(
      `https://${host}/v1/teams/${teamId}/databases/${databaseId}/exec`,
      {
        method: "POST",
        body: JSON.stringify({
          query: `getNextQuestion("${formId}")`,
        }),
        headers: {
          Authorization: `Bearer de9f31b0-0f96-11ee-8748-cd518a095f3d`,
          "Content-Type": "application/json",
        },
      }
    );

    const responseJSON = await response.json();
    console.log("responseJSON:", responseJSON);
    if (responseJSON.fill) {
      setProgress(responseJSON.fill);
      setGeneratorData(responseJSON);
    } else {
      setProgress(100);
    }

    setIsLoading(false);
  };

  const onSubmitAnswer = async (answer) => {
    setIsLoading(true);
    // get Question
    const response = await fetch(
      `https://${host}/v1/teams/${teamId}/databases/${databaseId}/exec`,
      {
        method: "POST",
        body: JSON.stringify({
          query: `answerQuestion(${JSON.stringify({
            shareId: formId,
            answerId: generatorData.question.qid,
            answer: answer.value.toString(),
            comment: "",
          })})`,
        }),
        headers: {
          Authorization: `Bearer de9f31b0-0f96-11ee-8748-cd518a095f3d`,
          "Content-Type": "application/json",
        },
      }
    );

    const responseJSON = await response.json();
    console.log("responseJSON:", responseJSON);

    if (responseJSON.next) {
      setProgress(responseJSON.next.fill);
      setGeneratorData(responseJSON.next);
    } else {
      setProgress(100);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    getForm();
  }, []);

  return (
    <main className="w-full mx-auto w-full max-w-xl mt-10">
      <style>{css}</style>
      <div
        dangerouslySetInnerHTML={{
          __html: header || "<div></div>",
        }}
        className="mb-8"
      />
      {/* 2 columns */}
      <div className="flex flex-col md:flex-row">
        <div className="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
          <div
            className="bg-blue-600 h-2.5 rounded-full"
            style={{
              width: `${progress}%`,
            }}
          ></div>
        </div>
      </div>
      <div className="flex flex-col md:flex-row">
        {!generatorData.question && (
          <div className="flex justify-center bg-white p-8 flex-1">
            <div className="text-2xl font-semibold text-gray-700 dark:text-gray-200">
              {isLoading ? "Loading..." : error || "No more questions"}
            </div>
          </div>
        )}
        {generatorData.question && (
          <div className="flex justify-center bg-white py-8 flex-1">
            <FormGenerator
              question={generatorData.question || {}}
              onSubmitAnswer={onSubmitAnswer}
            />
          </div>
        )}
      </div>
      <div
        dangerouslySetInnerHTML={{
          __html: footer || "<div></div>",
        }}
        className="mb-8"
      />
    </main>
  );
};

export default FormPage;
