import { RadioGroup } from "@headlessui/react";
import React from "react";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const List = ({ label, options, onChange, selected }) => {
  return (
    <div className="flex flex-col space-y-3">
      <label className="text-md font-semibold">{label}</label>

      <fieldset>
        <legend className="sr-only">{label}</legend>
        <div className="space-y-5">
          {options.map((option) => (
            <div key={option.name} className="relative flex items-start">
              <div className="flex h-6 items-center">
                <input
                  id={option.name}
                  aria-describedby={`${option.name}-description`}
                  name="option"
                  type="radio"
                  defaultChecked={option.name === "small"}
                  className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                  value={option.value}
                  onChange={(event) => {
                    // check if the option is selected
                    if (event.target.checked) {
                      // if it is selected, call the onChange function
                      onChange(option);
                    }
                  }}
                />
              </div>
              <div className="ml-3 text-sm leading-6">
                <label htmlFor={option.name}>
                  <span className="font-medium text-gray-900">
                    {option.name}
                  </span>
                  <br />
                  <span
                    id={`${option.name}-description`}
                    className="text-gray-500"
                  >
                    {option.description}
                  </span>
                </label>
              </div>
            </div>
          ))}
        </div>
      </fieldset>
    </div>
  );
};

export default List;
